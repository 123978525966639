<template>
  <div class="insert-event">

    <div class="insert-event-fields">
      <custom-select :label="$t('events.labels.priority')"
                     :default="newEvent.priority"
                     :options="priority"
                     v-model="newEvent.priority"/>
    </div>

    <div class="insert-event-fields">
      <custom-select :label="$t('events.labels.type')"
                     :default="newEvent.type"
                     :options="types"
                     v-model="newEvent.type"/>
    </div>

    <div class="insert-event-fields">
      <custom-validate-input :regex="subjectRegex"
                             :label="$t('events.labels.subject')"
                             :classes="{'w-full': true}"
                             align="right"
                             v-model="newEvent.subject"/>
    </div>

    <div class="insert-event-fields">
      <custom-validate-textarea :regex="contentRegex"
                                :label="$t('events.labels.content')"
                                :classes="{'w-full': true}"
                                align="right"
                                :rtl="true"
                                v-model="newEvent.content"/>
    </div>

    <button v-show="false"
            id="insertEventBTN"
            @click="insertEvent"/>
  </div>
</template>

<script>
import axios from 'axios'
import CustomValidateInput from '../../../../components/customInput/customValidateInput'
import CustomSelect from '../../../../components/customSelect/customSelect'
import CustomValidateTextarea from "../../../../components/customInput/customValidateTextarea";
import {insertEvent} from '../../../../http/requests/events'

export default {
  name: 'insertEvent',
  components: {CustomValidateTextarea, CustomSelect, CustomValidateInput},
  data () {
    return {
      subjectRegex: this.$validator('regex.event.subject'),
      contentRegex: this.$validator('regex.event.content'),
      types: [
        {
          label: this.$t('events.eventTypes.update'),
          value: 1
        },
        {
          label: this.$t('events.eventTypes.message'),
          value: 3
        },
        {
          label: this.$t('events.eventTypes.warning'),
          value: 4
        }
      ],
      priority: [
        {
          label: this.$t('events.priority.veryHigh'),
          value: 5
        },
        {
          label: this.$t('events.priority.high'),
          value: 4
        },
        {
          label: this.$t('events.priority.medium'),
          value: 3
        },
        {
          label: this.$t('events.priority.low'),
          value: 2
        },
        {
          label: this.$t('events.priority.veryLow'),
          value: 1
        }
      ],
      newEvent: {
        subject: {},
        content: {},
        type: {
          label: '-',
          value: 0
        },
        priority: {
          label: this.$t('events.priority.medium'),
          value: 3
        },
        users: []
      }
    }
  },
  methods: {
    insertEvent () {
      if (!this.newEvent.subject.isValid) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('events.validators.subject'),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }

      if (!this.newEvent.content.isValid) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('events.validators.content'),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }

      if (this.newEvent.type.value === 0) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('events.notifications.parseError.type'),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }

      if (this.newEvent.priority.value === 0) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('events.notifications.parseError.priority'),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }

      const event = {
        users: null,
        subject: this.newEvent.subject.value,
        content: this.newEvent.content.value,
        type: this.newEvent.type.value,
        priority: this.newEvent.priority.value
      }

      insertEvent(event).then(() => {
        this.$vs.notify({
          title: this.$t('alert.message.title'),
          text: this.$t('events.notifications.insert.success'),
          time: 2400,
          color: 'success',
          icon: 'icon-check',
          iconPack: 'feather'
        })

        this.$store.dispatch('helper/changeEvent')
      }).catch((error) => {
        if (axios.isCancel(error)) {
          this.$vs.notify({
            title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('events.notifications.insert.error'),
            time: 2400,
            color: 'danger',
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
.insert-event {

  .insert-event-fields {
    margin-top: 10px;
    position: relative;

    span {
      position: absolute;
      z-index: 300;
      font-size: 12px;
      top: -10px;
      left: 7px;
      background: #ffffff;
      padding: 0 5px;
    }
  }
}
</style>
